// Add your own custom CSS here.
@import "pygments-tomorrow";

.post-content img,
.post-excerpt-home img,
.post-nav img {
  @extend .img-rounded;
}
.post-content table,
.post-excerpt-home table,
.post-nav table {
  @extend .table;
  @extend .table-bordered;
}
